import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Modal } from '../modal';
import { BehaviorSubject } from 'rxjs';
import { UtilsService } from '../../services/utils.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-download-modal',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ButtonModule, ProgressSpinnerModule,
    ModalHeaderComponent,
  ],
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss']
})
export class DownloadModalComponent extends Modal {

  @Input() downloadUrl$ = new BehaviorSubject<string | null>(null);
  @Input() preparingLink$ = new BehaviorSubject<boolean>(false);

  constructor(
    private utilsService: UtilsService,
  ) {
    super();
  }

  onDownload() {
    const downloadUrl = this.downloadUrl$.getValue();
    if (!downloadUrl) return;
    this.utilsService.downloadMyFile(downloadUrl);
  }
}
