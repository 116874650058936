import { Injectable } from '@angular/core';
import { IOrder, IOrderFE, IOrderItemFE } from '../../models/order/order-model';
import { OrderState } from '../../enums/order/order-states.enum';
import { ReservationState } from '../../enums/reservation/reservation-states.enum';
import { PaymentsService } from '../entities/payments/payments.service';
import { ModalService } from '../modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../../enums/utils/languages.enum';
import { Subject, Subscription, take, tap } from 'rxjs';
import { IShopItem } from '../../models/shop-item/shop-item.model';
import { OrderEvent, ShopItemEvent } from 'src/app/pages/admin/org-admin/events/admin-event/components/shop-item-modules/shop-item-module-price-invoicing/si-maturity-start-input/si-maturity-start-input.component';
import { ISession } from '../../models/session-group/session.model';
import { ShopItemInvoicingMode } from '../../enums/shop-item/shop-item-invoicing-mode.enum';
import { IShopItemCustomSettings } from '../../models/shop-item/shop-item-custom-settings.model';
import { InvoiceType } from 'src/app/pages/admin/org-admin/events/admin-event/components/shop-item-modules/shop-item-module-price-invoicing/si-invoice-type-input/si-invoice-type-input.component';

@Injectable({
  providedIn: 'root'
})
export class OrderHelperService {

  constructor(
    private paymentsService: PaymentsService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  isOverdue(maturity: string): boolean {
    if (!maturity) return false;
    return new Date(maturity) < new Date();
  }

  isExpired(expiration: string): boolean {
    if (!expiration) return false;
    return new Date(expiration) < new Date();
  }

  isOrderItemCancellable(oi: IOrderItemFE, order: IOrderFE) {
    return (order.state === OrderState.AWAITING_ON_SITE_PAYMENT && oi.reservation.state === ReservationState.CONFIRMED);
  }

  getIsOrderItemCancelled(oi: IOrderItemFE) {
    // only for non CANCELLED orders
    return oi.reservation?.state === ReservationState.CANCELLED;
  }

  onPay(o: IOrderFE, paymentModalComponent: any /* PaymentModalComponent */) {
    const refreshState$ = new Subject<void>();

    const obs = this.paymentsService.getInstructions({
      orderId: o.orderId,
      lang: this.translate.currentLang as Language
    }).pipe(
      take(1),
      tap((res) => {
        const paymentInstructions = res;

        this.modalService.openPaymentModal(paymentModalComponent, paymentInstructions, o.orderUuid, !!o.companyBillingInfo, refreshState$);
      })
    );

    return {
      obs,
      refreshState$
    };
  }

  isPartiallyPaid(order: IOrderFE): boolean {
    const totalPaid = order.summary.totalPaid.value ?? 0;
    const totalPrice = order.summary.totalPrice.value ?? 0;
    return totalPaid > 0 && totalPaid < totalPrice;
  }

  isVatZero(order: IOrderFE): boolean {
    return order?.summary.vatValues.map(x => x.vat).sum() === 0 || (order?.summary.vatValues !== undefined && +order?.summary.vatValues.map(x => x.vat).sum() === 0); // TODO: Check why vatValue is sometimes string
  }

  public getInvoicingInfo(data: InvoicingInfoData) {
    const shopItem = data.shopItem;
    const showOrderAndIssueInvoiceBtn = shopItem.customSettings.invoiceIssueEvent === ShopItemEvent.ON_ORDER;
    let futureMaturityStart = shopItem.customSettings.maturityStart !== ShopItemEvent.ON_ORDER;
    let futureTaxInvoice = shopItem.customSettings.taxInvoiceEvent !== OrderEvent.ON_PAYMENT;

    let firstSession: ISession | undefined;
    if ('firstSession' in data) {
      firstSession = data.firstSession;
    } else {
      firstSession = data.shopItem?.sessionGroup?.sessions?.[0];
    }

    const now = new Date();
    let maturityStartDate: Date | null = null;
    let maturityStart: string | null = null;
    let invoiceDate: Date | null = null;
    let invoiceDateString: string | null = null;
    if (firstSession) {
      maturityStartDate = this.getInvoiceIssueDate(firstSession, shopItem.customSettings.maturityStart, shopItem.customSettings.maturityStartDays || 0, now);
      maturityStart = this.dateToDateString(maturityStartDate);

      if (shopItem.customSettings.invoiceType === InvoiceType.INVOICE) {
        invoiceDate = this.getInvoiceIssueDate(firstSession, shopItem.customSettings.invoiceIssueEvent, shopItem.customSettings.invoiceIssueDays || 0, now);
      } else {
        invoiceDate = this.getInvoiceIssueDate(firstSession, shopItem.customSettings.taxInvoiceEvent, shopItem.customSettings.taxInvoiceDays || 0, now);
      }
      invoiceDateString = this.dateToDateString(maturityStartDate);
    }

    if (maturityStartDate && now.getTime() >= maturityStartDate.getTime()) {
      futureMaturityStart = false;
    }

    if (invoiceDate && now.getTime() >= invoiceDate.getTime()) {
      futureTaxInvoice = false;
    }

    return { showOrderAndIssueInvoiceBtn, futureMaturityStart, maturityStart, invoiceDateString, futureTaxInvoice };
  }

  private getInvoiceIssueDate(session: ISession, start: OrderEvent | ShopItemEvent, days: number, now: Date) {
    if (start !== 'AFTER_START' && start !== 'BEFORE_START') return null;

    const nowDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const sessionStartAtDate = new Date(session.startAt);
    const sign = (start === 'AFTER_START') ? 1 : -1;
    const invoiceIssueDate = sessionStartAtDate.addDays(days * sign);
    // return max(nowDay, invoiceIssueDate);
    if (nowDay.getTime() > invoiceIssueDate.getTime()) {
      return nowDay;
    } else {
      return invoiceIssueDate;
    }
  }

  private dateToDateString(date: Date | null) {
    if (!date) {
      return null;
    }
  
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    return `${`${day}`.padStart(2, '0')}.${`${month}`.padStart(2, '0')}.${year}`;
  }
}

type InvoicingInfoData = {
  shopItem: IShopItem;
} | {
  shopItem: {
    invoicingMode: ShopItemInvoicingMode;
    customSettings: IShopItemCustomSettings;
  };
  firstSession: ISession;
};
