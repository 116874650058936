import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { IOrderFE, IOrderItemFE } from 'src/app/shared/models/order/order-model';
import { MenuItem } from 'primeng/api';
import { OrderState } from 'src/app/shared/enums/order/order-states.enum';
import { ReservationState } from 'src/app/shared/enums/reservation/reservation-states.enum';
import { IDiscountSummary } from 'src/app/shared/models/reservation/reservations-summary.model';
import { ModalService } from 'src/app/shared/services/modal.service';
import { CustomerCardOrderOrderItemDiscountModalComponent } from '../customer-card-order-order-item/customer-card-order-order-item-discount-modal/customer-card-order-order-item-discount-modal.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';
import { CustomerCardStoreService } from 'src/app/shared/services/store/customer-card-store.service';

@Component({
  selector: 'app-customer-card-order-order-item-discount',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    MenuModule, ButtonModule,
    PricePipe,
  ],
  templateUrl: './customer-card-order-order-item-discount.component.html',
  styleUrls: ['./customer-card-order-order-item-discount.component.scss']
})
export class CustomerCardOrderOrderItemDiscountComponent implements OnDestroy {
  ReservationState = ReservationState;

  @Input() discount: IDiscountSummary | undefined;
  _order: IOrderFE | undefined;
  get order() { return this._order; };
  @Input() set order(val: IOrderFE | undefined) { this._order = val; this.createMenuItems(); };
  _orderItem: IOrderItemFE | undefined;
  get orderItem() { return this._orderItem; };
  @Input() set orderItem(val: IOrderItemFE | undefined) { this._orderItem = val; this.createMenuItems(); };

  menuItems: MenuItem[] = [];

  subs: Subscription[] = [];

  constructor(
    private modalService: ModalService,
    private translate: TranslateService,
    private customerCardStore: CustomerCardStoreService
  ) {
    this.subs.push(
      this.translate.onLangChange.subscribe(() => { this.createMenuItems(); })
    );
  }

  createMenuItems() {
    const menuItems: MenuItem[] = [];

    if (!this.orderItem || !this.order) return;

    if (this.order?.state === OrderState.CANCELLED) return;
    if (this.order?.state === OrderState.PENDING) {
      if (this.orderItem?.reservation.state === ReservationState.APPROVED) {
        menuItems.push({
          label: this.translate.instant('admin.customer-card-order-order-item-discount.menu.edit-price.label'),
          icon: 'pi pi-pencil',
          command: () => this.onChangeOrderItemDiscountValue(),
        });
        menuItems.push({
          label: this.translate.instant('admin.customer-card-order-order-item-discount.menu.remove.label'),
          icon: 'pi pi-pencil',
          command: () => this.onRemoveOrderItemDiscount(),
        });
      }

    }

    this.menuItems = menuItems;
  }

  onChangeOrderItemDiscountValue() {
    if (!this.orderItem || !this.order || !this.discount) return;
    this.modalService.openOrderItemDiscountModal(CustomerCardOrderOrderItemDiscountModalComponent, this.orderItem.reservation.id, this.discount, this.order.orderId);
  }

  onRemoveOrderItemDiscount() {
    if (!this.orderItem || !this.order || !this.discount) return;
    this.customerCardStore.removeOrderItemDiscount(this.orderItem.reservation.id, this.discount.id, this.order.orderId).pipe(
      take(1)
    ).subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
