import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { IPrice } from '../../models/price/price.model';
import { CurrencyService } from '../../services/currency.service';
import { Currency } from '../../enums/price/currencies.enum';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';

export enum CurrencyChangerType {
  DROPDOWN = 'DROPDOWN',
  SELECT_BUTTON = 'SELECT_BUTTON',
  ICON = 'ICON',
}

@Component({
  selector: 'app-currency-changer',
  standalone: true,
  imports: [
    SelectButtonModule, DropdownModule, FormsModule,
  ],
  templateUrl: './currency-changer.component.html',
  styleUrl: './currency-changer.component.scss'
})
export class CurrencyChangerComponent implements OnInit, OnDestroy {
  CurrencyChangerType = CurrencyChangerType;

  _price: IPrice[] = [];
  get price() { return this._price; }
  @Input({required: true}) set price(val: IPrice[]) { this._price = val; this.createOptions(); };
  @Input({required: false}) type: CurrencyChangerType = CurrencyChangerType.ICON;
  @Input() appendTo: string | HTMLElement | undefined | null | 'icon' = null;

  currency: Currency = this.getCurrencyToSet();
  options: SelectItem[] = [];

  subs: Subscription[] = [];

  constructor(
    private currencyService: CurrencyService
  ) {
    this.subs.push(
      this.currencyService.getCurrency$().subscribe(c => {
        this.currency = this.getCurrencyToSet();
      })
    )
  }

  ngOnInit(): void {
    
  }


  onCurrencySelect(c: Currency) {
    this.currencyService.setCurrency(c);
  }

  private createOptions() {
    this.options = [];

    for (let price of this.price) {
      this.options.push({
        label: price.currency,
        value: price.currency
      });
    }
  }

  private getCurrencyToSet() {
    const curreny = this.currencyService.getCurrentCurrency();
    return this.price.find(p => p.currency === curreny)?.currency ?? this.price[0]?.currency;
  }


  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
